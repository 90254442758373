import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { useQuery } from '@apollo/react-hooks';
import SortIcon from '@material-ui/icons/Sort';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { isEmpty } from '../../utils/ObjectUtils';
import { PageListContent } from '../Common/styled/PageContent';
import { CardWrapperUI } from '../Common/styled/CardWrapperUI';
import Loading from '../Common/Loading';
import LoadMore from '../Pagination/LoadMore';
import { GET_VIRTUAL_VISITS } from '../../queries/VirtualVisits/VirtualVisits';
import { SectionBar } from '../../componentsUI/SectionBar';
import TableCollapse from '../../componentsUI/tableCollapse';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ContainerUI } from '../Common/styled/ContainerUI';
import { GetFormat } from '../../utils/functions';
import { AlertContainerUI, AlertUI } from '../../componentsUI/Alert';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
  },
  date: {
    color: theme.palette.grey.A200,
    fontSize: '1.25rem',
    '& strong': {
      color: theme.palette.primary.main,
    },
  },
  collapse: {
    marginLeft: 50,
    marginTop: -2,
    marginBottom: 10,
    fontSize: '1.125rem',
  },
  case: {
    color: theme.palette.primary.light,
    fontSize: '1.25rem',
    fontWeight: 600,
  },
}));

const fieldNames = [
  { label: 'patient', id: 'node.patient', width: 180, format: 'PATIENTNAME' },
  { label: 'appointment.scheduled', id: 'node.appointment', width: 160, format: 'DATEFORMAT', align: 'center' },
  { label: 'virtual.visit.answer', id: 'node.answered', width: 10, format: 'VIRTUALVISITREPLY' },
  { label: 'protocol', id: 'node.protocol.name', width: 225 },
];

export const VirtualVisitsView = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const parentRef = useRef();
  const user = useSelector((state) => state.userInterface.user);
  const hospital = useSelector((state) => state.hospital);
  const [selected, setSelected] = useState([]);
  const [serverError, setServerError] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const { loading, error, data, refetch, fetchMore } = useQuery(
    GET_VIRTUAL_VISITS, {
      variables: {
        doctorUuid: user.uuid,
        hospitalUuid: hospital.uuid,
        first: 50,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  if (!!error && !serverError) {
    setServerError(true);
  }

  if (data && data.virtualVisits && data.virtualVisits.edges && serverError && !error) {
    setServerError(false);
  }

  const fetchMoreCases = (fetchMoreCb) => {
    const { endCursor } = data.virtualVisits.pageInfo;
    fetchMore({
      query: GET_VIRTUAL_VISITS,
      variables: {
        doctorUuid: user.uuid,
        hospitalUuid: hospital.uuid,
        first: 50,
        cursor: endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult && fetchMoreResult.virtualVisits.edges;

        return newEdges.length ? {
          virtualVisits: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult.virtualVisits.__typename,
            totalCount: previousResult.virtualVisits.totalCount,
            edges: [...previousResult.virtualVisits.edges, ...newEdges],
            pageInfo: fetchMoreResult.virtualVisits.pageInfo,
          },
        } : previousResult;
      },
    })
      .then(({ data: fetchMoreData }) => (
        fetchMoreData.virtualVisits.pageInfo.hasNextPage && fetchMoreCb()
      ));
  };

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const item = data.virtualVisits.edges[index];

    navigate(`/virtual-visit/${item.node.uuid}`);
  };

  const getTitle = (item) => (
    item.node && item.node.patient && (
      <Box className={styles.title}>
        {GetFormat(item.node.answered, 'VIRTUALVISITREPLY')}
        {`${item.node.patient.name} ${item.node.patient.surname}`}
      </Box>
    )
  );

  const getSubTitle = (item) => (
    item.node && item.node.patient && (
      <Box>
        <span className={styles.date}>
          {`${t('appointment.scheduled')}: `}
          <strong>
            {GetFormat(item.node.appointment, 'DATEFORMAT')}
          </strong>
        </span>
        <br />
        {item.node.protocol.name}
      </Box>
    )
  );

  const getCollapse = ({ item }) => (
    item.node && item.node.medicalCase ? (
      <Box className={styles.collapse}>
        {`${t('medical.case')}: `}
        <span className={styles.case}>{item.node.medicalCase.title}</span>
      </Box>
    ) : <span />
  );

  const buttons = [
    { name: 'sort.by', icon: SortIcon, handleClick: () => {}, disabled: true },
  ];

  return (
    <ContainerUI>
      <Navbar>
        <SectionBar title="virtual.visits" items={buttons} />
      </Navbar>
      <Container maxWidth="lg" className="article">
        <PageListContent ref={parentRef}>
          { loading && isEmpty(data) && <Loading /> }
          { serverError && (
            <AlertContainerUI>
              <AlertUI severity="error" title="Error">
                {t('server.error')}
              </AlertUI>
            </AlertContainerUI>
          )}
          <CardWrapperUI>
            {
              data && data.virtualVisits && data.virtualVisits.edges
              && (
                <TableCollapse
                  fieldNames={fieldNames}
                  items={data.virtualVisits.edges}
                  GetTitle={getTitle}
                  GetSubtitle={getSubTitle}
                  GetCollapse={getCollapse}
                  selected={selected}
                  setSelected={setSelected}
                  handleGoto={handleGoto}
                  isMore={data
                    && data.virtualVisits
                    && data.virtualVisits.pageInfo
                    && data.virtualVisits.pageInfo.hasNextPage}
                  fetchMoreFn={fetchMoreCases}
                  // order={{ field: orderByField, direction: orderByDirection }}
                  // handleOrder={handleOrderBy}
                />
              )
            }
            {
              data
              && data.virtualVisits
              && data.virtualVisits.pageInfo
              && data.virtualVisits.pageInfo.hasNextPage
              && <LoadMore container parent={parentRef} fetchMoreFn={fetchMoreCases} />
            }
          </CardWrapperUI>
        </PageListContent>
      </Container>
    </ContainerUI>
  );
};
