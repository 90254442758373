import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container, Menu, MenuItem, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, OpenInNew, Sort } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, sortGraphQueryList } from '../../utils/ObjectUtils';
import { PageListContent } from '../Common/styled/PageContent';
import { CardWrapperUI } from '../Common/styled/CardWrapperUI';
import Loading from '../Common/Loading';
import { GET_PATIENT_VIRTUAL_VISITS } from '../../queries/VirtualVisits/VirtualVisits';
import { SectionBar } from '../../componentsUI/SectionBar';
import TableCollapse from '../../componentsUI/tableCollapse';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ContainerUI } from '../Common/styled/ContainerUI';
import { GetFormat } from '../../utils/functions';

const useStyles = makeStyles(() => ({
  subtitle: {
    fontSize: '0.875em',
    lineHeight: 1.2,
    whiteSpace: 'break-spaces',
  },
}));

const fieldNames = [
  { label: 'reply.completed', id: 'node.answered', field: 'ANSWERED', width: 10, format: 'VIRTUALVISITREPLY' },
  { label: 'appointment', id: 'node.appointment', field: 'APPOINTMENT', width: 100, format: 'DATEFORMAT', align: 'center' },
  { label: 'protocol', id: 'node.protocol.name', field: 'PROTOCOL', width: 150 },
  { label: 'medical.case', id: 'node.medicalCase.title', width: 150 },
  { label: 'doctor', id: 'node.doctor.fullname', width: 150 },
];

export const VirtualVisitsPatientView = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const user = useSelector((state) => state.userInterface.user);
  const [selected, setSelected] = useState([]);

  const sortData = {
    default: { field: 'APPOINTMENT', direction: 'DESC' },
  };
  const [orderByField, setOrderByField] = useState(sortData.default.field);
  const [orderByDirection, setOrderByDirection] = useState(sortData.default.direction);

  // eslint-disable-next-line no-unused-vars
  const { loading, error, data, refetch, fetchMore } = useQuery(
    GET_PATIENT_VIRTUAL_VISITS, {
      variables: {
        patientUuid: user.patientUuid,
        first: 100,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const reverseDirection = () => (orderByDirection === 'ASC' ? 'DESC' : 'ASC');

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === orderByField) {
        setOrderByDirection(reverseDirection());
      } else {
        setOrderByDirection(value.direction);
      }

      setOrderByField(value.field);
      refetch();
      setAnchorEl();
      setSelected([]);
    }
  };

  const virtualVisits = data && data.virtualVisits && data.virtualVisits.edges
    && data.virtualVisits.edges.length > 0 && sortGraphQueryList(
    data.virtualVisits.edges,
    fieldNames,
    orderByField,
    orderByDirection,
  ).slice(0, 100);

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const item = virtualVisits[index];

    navigate(`/virtual-visit/${item.node.uuid}`);
  };

  const selectedGoto = (evt) => {
    evt.stopPropagation();
    const item = virtualVisits[selected[0]];

    navigate(`/virtual-visit/${item.node.uuid}`);
  };

  const getTitle = (item) => (
    `${item.node && item.node.protocol && item.node.protocol.name} - ${item.node && item.node.medicalCase && item.node.medicalCase.title}`
  );

  const getSubTitle = (item) => {
    const date = item.node && item.node.appointment && GetFormat(item.node.appointment, 'DATEFORMAT');
    const answered = item.node && item.node.answered === true ? t('reply.completed') : t('pending.to.reply');

    return (
      <Typography className={styles.subtitle}>
        {`${t('doctor')}: ${item.node && item.node.doctor && item.node.doctor.fullname}`}
        <br />
        {`${t('appointment.scheduled')}: ${date}`}
        <br />
        {answered}
      </Typography>
    );
  };

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const buttons = [
    { name: 'open', icon: OpenInNew, handleClick: selectedGoto, disabled: selected.length === 0 },
    { name: 'divider2', type: 'divider' },
    { name: 'sort.by', icon: Sort, handleClick: handleOrder },
  ];

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDown className={className} /> : <ArrowDropUp className={className} />
  );

  return (
    <ContainerUI>
      <Navbar>
        <SectionBar title="virtual.visits" items={buttons} />
        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === orderByField) && <OrderIcon direction={orderByDirection} />}
            </MenuItem>
          ))}
        </Menu>
      </Navbar>
      <Container maxWidth="lg" className="article">
        <PageListContent>
          { loading && isEmpty(data) && <Loading /> }
          <CardWrapperUI>
            { data && data.virtualVisits && data.virtualVisits.edges && data.virtualVisits.edges.length > 0
              ? (
                <TableCollapse
                  fieldNames={fieldNames}
                  items={virtualVisits}
                  GetTitle={getTitle}
                  GetSubtitle={getSubTitle}
                  // GetCollapse={PatientCollapse}
                  selected={selected}
                  setSelected={setSelected}
                  handleGoto={handleGoto}
                  // fetchMoreFn={fetchMoreCases}
                  order={{ field: orderByField, direction: orderByDirection }}
                  handleOrder={handleOrderBy}
                />
              ) : (t('no.virtual.visits'))}
          </CardWrapperUI>
        </PageListContent>
      </Container>
    </ContainerUI>
  );
};
