import React from 'react';

import Layout from '../../components/MenuLayout';
import { VirtualVisitsView } from '../../components/VirtualVisits/VirtualVisitsView';
import { VirtualVisitsPatientView } from '../../components/VirtualVisits/VirtualVisitsPatientView';
import { getUserInterface } from '../../utils/patientUserHospitalUtils';

const userInterface = getUserInterface();

export default () => (
  <Layout>
    {userInterface === 'patient' ? <VirtualVisitsPatientView /> : <VirtualVisitsView />}
  </Layout>
);
